import { registerApplication, start, getMountedApps, getAppNames, getAppStatus } from "single-spa";
import { constructApplications, constructLayoutEngine } from "single-spa-layout";


export function createSingleSpaRouter() {
  const singleSpaRouter = document.createElement("single-spa-router");
  const routeDefault = document.createElement("route");
  routeDefault.setAttribute("default", "");

  routeDefault.innerHTML = `
  <v-app theme="dark">
    <route path="/shield">
      <application name="@one/shield-app"></application>
    </route>
    <route path="/nexus">
      <application name="@one/nexus-app"></application>
    </route>
    <route path="/app">
      <application name="@one/one-app"></application>
    </route>
    <redirect from="/" to="/app"></redirect>
  </v-app>
    `;


  singleSpaRouter.appendChild(routeDefault);
  return singleSpaRouter;
}
export function constructSingleSpaApplications(routes) {
  const applications = constructApplications({
    routes,
    loadApp({ name }) {

      return System.import(name);
    },
  });
  return applications;
}

export function activateLayoutEngine(routes, applications) {
  const layoutEngine = constructLayoutEngine({ routes, applications });

  layoutEngine.activate();
}

export function registerApplications(applications) {
  applications.forEach(registerApplication);
}