import { navigateToUrl, start } from "single-spa";
import {
  constructRoutes,
} from "single-spa-layout";

import { activateLayoutEngine, constructSingleSpaApplications, createSingleSpaRouter, registerApplications } from "./utils/singleSpa";
import { enableMocking } from "./mocks/browser";

const singleSpaRouter = createSingleSpaRouter();
const routes = constructRoutes(singleSpaRouter);
const applications = constructSingleSpaApplications(routes);


function listenCustomEventChangeRoute() {
  window.addEventListener('changeRoute', (event: Event) => {
    const customEvent = event as CustomEvent
    if (customEvent.detail) {
      navigateToUrl(customEvent.detail.path)
    }
  })
}

enableMocking().then(() => {
  listenCustomEventChangeRoute()
  registerApplications(applications);
  activateLayoutEngine(routes, applications);
  start({
    urlRerouteOnly: true,
  })
});
