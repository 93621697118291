import { start } from "single-spa";
import {
  constructRoutes,
} from "single-spa-layout";

// import { applyPolyfills } from "@clearsale/material-ds/dist/loader"
// import "@clearsale/material-ds/dist/clearsale-material-ds/clearsale-material-ds.css"

import { activateLayoutEngine, constructSingleSpaApplications, createSingleSpaRouter, registerApplications } from "./utils/singleSpa";
// import { loadResources } from "./utils/loadResources";

// applyPolyfills().then(() => {
//   loadResources();
// });

const singleSpaRouter = createSingleSpaRouter();
const routes = constructRoutes(singleSpaRouter);
const applications = constructSingleSpaApplications(routes);



registerApplications(applications);
activateLayoutEngine(routes, applications);
start({
  urlRerouteOnly: true,
})



